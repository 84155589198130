export default function useCurrency() {
  const { n } = useI18n()

  const businessStore = useBusinessStore()

  const formatCurrency = (rawPrice: number, currency: Currency = 'EUR'): string => {
    return n(rawPrice, {
      currency: businessStore.billing.currency ?? currency,
      style: 'currency',
      currencyDisplay: 'code',
      maximumFractionDigits: rawPrice % 1 === 0 ? 0 : 2,
    })
  }

  return {
    formatCurrency,
  }
}
